import React from "react";

class ComingSoon extends React.Component {
  render() {
    return (
      <div className="bg-temporary p-14 min-h-screen">
        <div className="container mx-auto">
          <h1 className="text-center text-7xl tracking-widest text-temptext">
            Coming Soon...
          </h1>
          <div className="h-full">
            <img
              src="happy_friday.png"
              alt="man riding skateboard"
              className="mt-10 h-96 mx-auto rounded-md"
            ></img>
          </div>
        </div>
      </div>
    );
  }
}

export default ComingSoon;
